import {
  GET_ADMIN_DASHBOARD_SUCCEEDED,
  GET_ADMIN_DASHBOARD_FAILED,
  GET_ADMIN_DASHBOARD,
  GET_ANALYTICS_USER,
  GET_ANALYTICS_USER_SUCCEEDED,
  GET_ANALYTICS_USER_FAILED,
} from '../../src/actions';
import { IDashboard } from '../interfaces/dashboard';
import { getLocalStore } from 'next-persist';

const initialState: IDashboard = {
  adminDashboard: {},
  analyticsUser: {
    live_user_count: [],
    users_last_hour: [],
    total_last_hour_user: 0,
  },
  getAdminDashboardStatus: '',
  getAnalyticsUserStatus: '',
};
const persistedState = getLocalStore('dashboard', initialState);

const dashboard = (state = persistedState, action) => {
  switch (action.type) {
    case GET_ADMIN_DASHBOARD:
      return {
        ...state,
        getAdminDashboardStatus: 'request',
      };
    case GET_ADMIN_DASHBOARD_SUCCEEDED:
      return {
        ...state,
        adminDashboard: action.data,
        getAdminDashboardStatus: 'success',
      };

    case GET_ADMIN_DASHBOARD_FAILED:
      return {
        ...state,
        getAdminDashboardStatus: 'failure',
      };
    case GET_ANALYTICS_USER:
      return {
        ...state,
        getAnalyticsUserStatus: 'request',
      };
    case GET_ANALYTICS_USER_SUCCEEDED:
      return {
        ...state,
        analyticsUser: action.data,
        getAnalyticsUserStatus: 'success',
      };
    case GET_ANALYTICS_USER_FAILED:
      return {
        ...state,
        getAnalyticsUserStatus: 'failure',
      };
    default:
      return state;
  }
};

export default dashboard;
