import { takeLatest, all } from 'redux-saga/effects';

import * as actions from '../actions';
import { fetchData, postData, deleteData, putData } from './genericSagas';
import authWatcher from './auth';
import contactWatcher from './contact';
import coreWatcher from './core';
import informationWatcher from './information';

export default function* rootSaga() {
  yield takeLatest(actions.GET_COURSE_WISHLIST, fetchData);

  // Refer
  yield takeLatest(actions.GET_REFER_DETAIL, fetchData);
  yield takeLatest(actions.GET_REFER_LIST, fetchData);
  // LearnSIC USers
  yield takeLatest(actions.ADD_USER, postData);
  yield takeLatest(actions.GET_USERS, fetchData);
  yield takeLatest(actions.SEARCH_USER, fetchData);
  yield takeLatest(actions.GET_USER_DETAIL, fetchData);
  yield takeLatest(actions.EDIT_USER, putData);
  yield takeLatest(actions.DELETE_USER, deleteData);

  // Clients Tasks
  yield takeLatest(actions.GET_CLIENT_TASKS_REQUEST, fetchData);
  yield takeLatest(actions.ADD_CLIENT_TASKS_REQUEST, postData);
  yield takeLatest(actions.EDIT_CLIENT_TASKS_REQUEST, putData);
  yield takeLatest(actions.DELETE_CLIENT_TASKS_REQUEST, deleteData);

  // Clients Notes
  yield takeLatest(actions.GET_CLIENT_NOTES_REQUEST, fetchData);
  yield takeLatest(actions.ADD_CLIENT_NOTES_REQUEST, postData);
  yield takeLatest(actions.EDIT_CLIENT_NOTES_REQUEST, putData);
  yield takeLatest(actions.DELETE_CLIENT_NOTES_REQUEST, deleteData);

  //Client Payment History
  yield takeLatest(actions.GET_CLIENT_PAYMENT_HISTORY_REQUEST, fetchData);

  //Edit Tutor
  yield takeLatest(actions.EDIT_TUTOR, putData);

  yield takeLatest(actions.ADD_TUTOR_EDUCATION_INFO, postData);
  yield takeLatest(actions.EDIT_TUTOR_EDUCATION_INFO, putData);
  yield takeLatest(actions.DELETE_TUTOR_EDUCATION_INFO, deleteData);

  yield takeLatest(actions.ADD_TUTOR_EXPERIENCE_INFO, postData);
  yield takeLatest(actions.EDIT_TUTOR_EXPERIENCE_INFO, putData);
  yield takeLatest(actions.DELETE_TUTOR_EXPERIENCE_INFO, deleteData);

  // Tutor Request
  yield takeLatest(actions.ADD_REQUEST_COURSE_FORM, postData);

  // Tutor Detail page
  yield takeLatest(actions.GET_TUTOR_DETAIL, fetchData);

  // My Attempted Tests
  yield takeLatest(actions.GET_MY_ATTEMPTED_TESTS, fetchData);
  yield takeLatest(actions.ENROL_MOCK_TEST, postData);

  // Course Reviews
  yield takeLatest(actions.GET_COURSE_REVIEW, fetchData);
  yield takeLatest(actions.GET_COURSE_REVIEW_BY_PAGE, fetchData);
  yield takeLatest(actions.ADD_COURSE_REVIEW, postData);
  yield takeLatest(actions.EDIT_COURSE_REVIEW, putData);
  yield takeLatest(actions.DELETE_COURSE_REVIEW, deleteData);
  // Like and dislike course review
  yield takeLatest(actions.LIKE_COURSE_REVIEW, postData);
  yield takeLatest(actions.DISLIKE_COURSE_REVIEW, postData);

  // landing
  yield takeLatest(actions.GET_HOME, fetchData);
  yield takeLatest(actions.GET_HOME_SEARCH, fetchData);
  yield takeLatest(actions.GET_LANDING_FAQ, fetchData);
  yield takeLatest(actions.GET_INFO, fetchData);
  yield takeLatest(actions.GET_COMPANY_INFO, fetchData);
  yield takeLatest(actions.GET_BLOG, fetchData);
  yield takeLatest(actions.GET_BLOG_DETAIL, fetchData);
  yield takeLatest(actions.GET_COURSE_LIST, fetchData);
  yield takeLatest(actions.GET_COURSES_BY_PAGE, fetchData);
  yield takeLatest(actions.GET_COURSE_DETAIL, fetchData);
  yield takeLatest(actions.GET_COURSE_ENROL_DETAIL, fetchData);
  yield takeLatest(actions.UPDATE_COURSE_ENROL_DETAIL, putData);
  yield takeLatest(actions.ADD_COURSE_ENROL_CONTENT, postData);
  yield takeLatest(actions.GET_COURSE_VIDEO, fetchData);
  yield takeLatest(actions.GET_COURSE_CATEGORY_LIST, fetchData);
  yield takeLatest(actions.ADD_CONTACT, postData);

  // Course Material
  yield takeLatest(actions.GET_COURSE_MATERIAL, fetchData);
  yield takeLatest(actions.GET_COURSE_MATERIAL_BY_ID, fetchData);

  //courses
  yield takeLatest(actions.GET_COURSES, fetchData);
  yield takeLatest(actions.GET_COURSE_BY_SLUG, fetchData);
  yield takeLatest(actions.ADD_COURSE_TO_WISHLIST, postData);
  yield takeLatest(actions.ADD_COURSE, postData);
  yield takeLatest(actions.EDIT_COURSE, putData);
  yield takeLatest(actions.DELETE_COURSE, deleteData);

  // Notes Course landing
  yield takeLatest(actions.GET_NOTES, fetchData);
  yield takeLatest(actions.ADD_NOTES, postData);
  yield takeLatest(actions.EDIT_NOTES, putData);
  yield takeLatest(actions.DELETE_NOTES, deleteData);

  // Question Course Landing
  yield takeLatest(actions.GET_QUESTION_DOUBTS, fetchData);
  yield takeLatest(actions.GET_QUESTION_DOUBTS_BY_PAGE, fetchData);
  yield takeLatest(actions.GET_QUESTION_DOUBTS_DETAIL, fetchData);
  yield takeLatest(actions.GET_QUESTION_DOUBT_COMMENT, fetchData);
  yield takeLatest(actions.GET_QUESTION_DOUBT_COMMENT_BY_PAGE, fetchData);
  yield takeLatest(actions.ADD_QUESTION_DOUBTS, postData);
  yield takeLatest(actions.EDIT_QUESTION_DOUBTS, putData);
  yield takeLatest(actions.DELETE_QUESTION_DOUBTS, deleteData);
  yield takeLatest(actions.ADD_QUESTION_DOUBT_COMMENT, postData);
  yield takeLatest(actions.EDIT_QUESTION_DOUBT_COMMENT, putData);
  yield takeLatest(actions.DELETE_QUESTION_DOUBT_COMMENT, deleteData);
  yield takeLatest(actions.LIKE_COURSE_QUESTION, postData);
  yield takeLatest(actions.LIKE_QUESTION_DOUBT_COMMENT, postData);

  yield takeLatest(actions.GET_USER_DOUBTS, fetchData);

  //subjects
  yield takeLatest(actions.GET_SUBJECTS, fetchData);
  yield takeLatest(actions.GET_SUBJECT_BY_SLUG, fetchData);
  yield takeLatest(actions.GET_SUBJECTS_BY_PAGE, fetchData);
  yield takeLatest(actions.ADD_SUBJECT, postData);
  yield takeLatest(actions.EDIT_SUBJECT, putData);
  yield takeLatest(actions.COPY_SUBJECT_CONTENT, putData);
  yield takeLatest(actions.DELETE_SUBJECT, deleteData);

  //chapters
  yield takeLatest(actions.GET_CHAPTERS, fetchData);
  yield takeLatest(actions.GET_CHAPTER_BY_SLUG, fetchData);
  yield takeLatest(actions.ADD_CHAPTER, postData);
  yield takeLatest(actions.EDIT_CHAPTER, putData);
  yield takeLatest(actions.DELETE_CHAPTER, deleteData);

  //tests
  yield takeLatest(actions.GET_TESTS, fetchData);
  yield takeLatest(actions.ADD_TEST, postData);
  yield takeLatest(actions.EDIT_TEST, putData);
  yield takeLatest(actions.DELETE_TEST, deleteData);

  //content
  yield takeLatest(actions.GET_CONTENTS, fetchData);
  yield takeLatest(actions.GET_CONTENT_BY_SLUG, fetchData);
  yield takeLatest(actions.ADD_CONTENT, postData);
  yield takeLatest(actions.EDIT_CONTENT, putData);
  yield takeLatest(actions.DELETE_CONTENT, deleteData);
  yield takeLatest(actions.ADD_CONTENT_FILE, postData);
  yield takeLatest(actions.EDIT_CONTENT_FILE, putData);
  yield takeLatest(actions.DELETE_CONTENT_FILE, deleteData);

  //video
  yield takeLatest(actions.GET_VIDEO_BY_SLUG, fetchData);
  yield takeLatest(actions.ADD_VIDEO, postData);
  yield takeLatest(actions.ADD_COURSE_ENROLL, postData);
  yield takeLatest(actions.GET_COURSE_ENROLL, fetchData);

  // Admin Enrol Data
  yield takeLatest(actions.GET_COURSE_CLIENT, fetchData);
  yield takeLatest(actions.ADD_COURSE_CLIENTS, postData);
  yield takeLatest(actions.EDIT_COURSE_CLIENTS, putData);
  yield takeLatest(actions.GET_MOCK_TEST_CLIENT, fetchData);
  yield takeLatest(actions.GET_MOCK_TEST_LIST, fetchData);
  yield takeLatest(actions.GET_MOCK_TEST_LIST_BY_PAGE, fetchData);
  yield takeLatest(actions.GET_MOCK_TEST_BY_SLUG, fetchData);
  yield takeLatest(actions.GET_MOCK_TEST_LIST_BY_SLUG, fetchData);
  yield takeLatest(actions.UPDATE_MOCK_TEST, putData);

  // Assign multiple mock test
  yield takeLatest(actions.ASSIGN_MULTIPLE_MOCK_TEST_REQUEST, postData);

  // Admin get Users Reviews
  yield takeLatest(actions.GET_USER_REVIEWS, fetchData);

  // Related Course
  yield takeLatest(actions.GET_RELATED_COURSE, fetchData);

  //quiz
  yield takeLatest(actions.GET_QUIZ, fetchData);
  yield takeLatest(actions.GET_QUIZ_BY_SLUG, fetchData);
  yield takeLatest(actions.ADD_QUIZ, postData);
  yield takeLatest(actions.EDIT_QUIZ, putData);
  yield takeLatest(actions.DELETE_QUIZ, deleteData);

  //quiz question
  yield takeLatest(actions.GET_QUIZ_QUESTIONS, fetchData);
  yield takeLatest(actions.ADD_QUIZ_QUESTION, postData);
  yield takeLatest(actions.EDIT_QUIZ_QUESTION, putData);
  yield takeLatest(actions.DELETE_QUIZ_QUESTION, deleteData);

  //quiz answer
  yield takeLatest(actions.GET_QUIZ_ANSWERS, fetchData);
  yield takeLatest(actions.ADD_QUIZ_ANSWER, postData);
  yield takeLatest(actions.EDIT_QUIZ_ANSWER, putData);
  yield takeLatest(actions.DELETE_QUIZ_ANSWER, deleteData);

  yield takeLatest(actions.GET_TUTOR_TERMS_CONDITIONS, fetchData);

  // yield takeLatest(actions.GET_QUESTION, fetchQuizData);
  yield takeLatest(actions.GET_QUESTION, fetchData);
  yield takeLatest(actions.GET_QUIZ_ANSWER_SHEET, fetchData);
  yield takeLatest(actions.GET_QUIZ_SUMMARY, fetchData);
  yield takeLatest(actions.ADD_QUIZ_ANSWER_SHEET, postData);

  //MODEL TEST
  yield takeLatest(actions.GET_MODEL_TESTS, fetchData);
  yield takeLatest(actions.GET_MODEL_TEST_BY_SLUG, fetchData);
  yield takeLatest(actions.ADD_MODEL_TEST, postData);
  yield takeLatest(actions.EDIT_MODEL_TEST, putData);
  yield takeLatest(actions.DELETE_MODEL_TEST, deleteData);
  yield takeLatest(actions.GET_MODEL_TEST_CLIENTS, fetchData);
  yield takeLatest(actions.ADD_MODEL_TEST_CLIENT, postData);

  //model test sections
  yield takeLatest(actions.GET_MODEL_TEST_SECTIONS, fetchData);
  yield takeLatest(actions.GET_MODEL_SECTION_BY_ID, fetchData);
  yield takeLatest(actions.ADD_MODEL_TEST_SECTION, postData);
  yield takeLatest(actions.EDIT_MODEL_TEST_SECTION, putData);
  yield takeLatest(actions.DELETE_MODEL_TEST_SECTION, deleteData);

  //model test questions
  yield takeLatest(actions.GET_QUESTIONS, fetchData);
  yield takeLatest(actions.GET_MODEL_TEST_QUESTION_BY_ID, fetchData);
  yield takeLatest(actions.ADD_QUESTION, postData);
  yield takeLatest(actions.EDIT_QUESTION, putData);
  yield takeLatest(actions.DELETE_QUESTION, deleteData);

  //model test answers
  yield takeLatest(actions.GET_ANSWERS, fetchData);
  yield takeLatest(actions.ADD_ANSWER, postData);
  yield takeLatest(actions.EDIT_ANSWER, putData);
  yield takeLatest(actions.DELETE_ANSWER, deleteData);

  //model test group answers
  yield takeLatest(actions.GET_GROUP_ANSWERS, fetchData);
  yield takeLatest(actions.ADD_GROUP_ANSWER, postData);
  yield takeLatest(actions.EDIT_GROUP_ANSWER, putData);
  yield takeLatest(actions.DELETE_GROUP_ANSWER, deleteData);

  // Doubts
  yield takeLatest(actions.GET_DOUBT_POST, fetchData);
  yield takeLatest(actions.GET_DOUBT_POST_BY_PAGE, fetchData);
  yield takeLatest(actions.GET_DOUBT_DETAIL_POST, fetchData);
  yield takeLatest(actions.SEARCH_DOUBT_QUESTION, fetchData);

  yield takeLatest(actions.ADD_DOUBT_POST, postData);
  yield takeLatest(actions.ADD_DOUBT_POLL, postData);
  yield takeLatest(actions.VOTE_DOUBT_POLL, postData);
  yield takeLatest(actions.EDIT_DOUBT_POST, putData);
  yield takeLatest(actions.DELETE_DOUBT_POST, deleteData);

  yield takeLatest(actions.ADD_DOUBT_POST_LIKE, postData);
  yield takeLatest(actions.GET_DOUBT_COMMENT, fetchData);
  yield takeLatest(actions.GET_DOUBT_COMMENT_FOR_DETAIL_PAGE, fetchData);
  yield takeLatest(actions.GET_LATEST_COURSE, fetchData);
  yield takeLatest(actions.GET_DOUBT_TOPIC, fetchData);
  yield takeLatest(actions.ADD_DOUBT_TOPIC, postData);
  yield takeLatest(actions.ADD_DOUBT_COMMENT, postData);
  yield takeLatest(actions.EDIT_DOUBT_COMMENT, putData);
  yield takeLatest(actions.DELETE_DOUBT_COMMENT, deleteData);
  yield takeLatest(actions.REPORT_DOUBT_POST, postData);
  yield takeLatest(actions.REPORT_DOUBT_COMMENT, postData);

  // Top doubt topic
  yield takeLatest(actions.GET_TOP_TOPICS, fetchData);

  // Admin Dashboard
  yield takeLatest(actions.GET_POST_REPORTS, fetchData);
  yield takeLatest(actions.EDIT_POST_REPORTS, putData);
  yield takeLatest(actions.GET_COMMENT_REPORTS, fetchData);
  yield takeLatest(actions.EDIT_COMMENT_REPORTS, putData);
  yield takeLatest(actions.GET_POST_REQUESTS, fetchData);
  yield takeLatest(actions.EDIT_DOUBT_REQUESTS_ADMIN, putData);
  yield takeLatest(actions.EDIT_COMMENT_ADMIN, putData);
  yield takeLatest(actions.GET_REQUESTED_COURSE_INQUIRY, fetchData);

  yield takeLatest(actions.GET_CONTACT_US_USER_DETAIL, fetchData);

  //For updating course request status
  yield takeLatest(actions.EDIT_REQUESTED_COURSE_INQUIRY_STATUS, putData);

  //course Inquiries comment
  yield takeLatest(actions.GET_COURSE_COMMENTS_REQUEST, fetchData);
  yield takeLatest(actions.ADD_COURSE_COMMENTS_REQUEST, postData);
  yield takeLatest(actions.EDIT_COURSE_COMMENTS_REQUEST, putData);
  yield takeLatest(actions.DELETE_COURSE_COMMENTS_REQUEST, deleteData);

  //Analytics Dashboard
  yield takeLatest(actions.GET_ANALYTICS_USER, fetchData);

  // Advertisement
  yield takeLatest(actions.GET_ADVERTISEMENT_FOR_PAGE, fetchData);

  // CRUD Advertisement for Admin
  yield takeLatest(actions.GET_ADVERTISEMENT, fetchData);
  yield takeLatest(actions.ADD_ADVERTISEMENT, postData);
  yield takeLatest(actions.EDIT_ADVERTISEMENT, putData);
  yield takeLatest(actions.DELETE_ADVERTISEMENT, deleteData);

  // Notifications
  yield takeLatest(actions.GET_NOTIFICATIONS, fetchData);
  yield takeLatest(actions.GET_NOTIFICATIONS_BY_PAGE, fetchData);

  yield takeLatest(actions.READ_ALL_NOTIFICATIONS, postData);
  yield takeLatest(actions.READ_SINGLE_NOTIFICATION, postData);
  yield takeLatest(actions.SEND_NOTIFICATION_FOR_TUTOR_REVIEW, putData);

  //Admin dashboard
  yield takeLatest(actions.GET_ADMIN_DASHBOARD, fetchData);

  //USER PROFILE
  //basic info
  yield takeLatest(actions.GET_USER_BASIC_INFO, fetchData);
  yield takeLatest(actions.EDIT_USER_BASIC_INFO, putData);

  //social info
  yield takeLatest(actions.GET_USER_SOCIAL_INFO, fetchData);
  yield takeLatest(actions.EDIT_USER_SOCIAL_INFO, putData);

  //education
  yield takeLatest(actions.GET_USER_EDUCATION_INFO, fetchData);
  yield takeLatest(actions.ADD_USER_EDUCATION_INFO, postData);
  yield takeLatest(actions.EDIT_USER_EDUCATION_INFO, putData);
  yield takeLatest(actions.DELETE_USER_EDUCATION_INFO, deleteData);

  //experience
  yield takeLatest(actions.GET_USER_EXPERIENCE_INFO, fetchData);
  yield takeLatest(actions.ADD_USER_EXPERIENCE_INFO, postData);
  yield takeLatest(actions.EDIT_USER_EXPERIENCE_INFO, putData);
  yield takeLatest(actions.DELETE_USER_EXPERIENCE_INFO, deleteData);

  //course subcontents

  yield takeLatest(actions.GET_COURSE_DOUBTS, fetchData);
  yield takeLatest(actions.GET_COURSE_DOUBT_COMMENTS, fetchData);
  yield takeLatest(actions.ADD_COURSE_DOUBT_COMMENTS, postData);
  yield takeLatest(actions.EDIT_COURSE_DOUBT_COMMENTS, putData);
  yield takeLatest(actions.DELETE_COURSE_DOUBT_COMMENTS, deleteData);
  yield takeLatest(actions.GET_COURSE_CLIENTS, fetchData);
  yield takeLatest(actions.GET_COURSE_FEEDBACKS, fetchData);
  yield takeLatest(actions.GET_COURSE_FEEDBACK_BY_ID, fetchData);

  // Rooms
  yield takeLatest(actions.GET_ROOM_INSTRUCTOR, fetchData);
  yield takeLatest(actions.GET_ROOM_INSTRUCTOR_BY_SLUG, fetchData);
  yield takeLatest(actions.ADD_ROOM_INSTRUCTOR, postData);
  yield takeLatest(actions.EDIT_ROOM_INSTRUCTOR, putData);
  yield takeLatest(actions.DELETE_ROOM_INSTRUCTOR, deleteData);

  // Announcement
  yield takeLatest(actions.GET_ANNOUNCEMENT_INSTRUCTOR, fetchData);
  yield takeLatest(actions.ADD_ANNOUNCEMENT_INSTRUCTOR, postData);
  yield takeLatest(actions.EDIT_ANNOUNCEMENT_INSTRUCTOR, putData);
  yield takeLatest(actions.DELETE_ANNOUNCEMENT_INSTRUCTOR, deleteData);

  // Room Frontend
  yield takeLatest(actions.GET_ROOM, fetchData);
  yield takeLatest(actions.GET_ROOM_DETAIL, fetchData);

  // Announcement Frontend
  yield takeLatest(actions.GET_ANNOUNCEMENT, fetchData);

  // Room Meetings
  yield takeLatest(actions.GET_ROOM_MEETINGS_INSTRUCTOR, fetchData);
  yield takeLatest(actions.ADD_ROOM_MEETINGS_INSTRUCTOR, postData);
  yield takeLatest(actions.EDIT_ROOM_MEETINGS_INSTRUCTOR, putData);
  yield takeLatest(actions.DELETE_ROOM_MEETINGS_INSTRUCTOR, deleteData);

  // Room Meetings Frontend
  yield takeLatest(actions.GET_ROOM_MEETINGS, fetchData);
  yield takeLatest(actions.GET_ROOM_MEETINGS_BY_SLUG, fetchData);

  // Room Discussions
  yield takeLatest(actions.GET_ROOM_DISCUSSION, fetchData);
  yield takeLatest(actions.GET_ROOM_DISCUSSION_BY_PAGE, fetchData);
  yield takeLatest(actions.ADD_ROOM_DISCUSSION, postData);
  yield takeLatest(actions.ROOM_DISCUSSION_LIKE, postData);
  yield takeLatest(actions.GET_DISCUSSION_REACTION_LIST, fetchData);
  yield takeLatest(actions.EDIT_ROOM_DISCUSSION, putData);
  yield takeLatest(actions.DELETE_ROOM_DISCUSSION, deleteData);

  // Room Discussion Comment
  yield takeLatest(actions.GET_ROOM_DISCUSSION_COMMENT, fetchData);
  yield takeLatest(actions.GET_ROOM_DISCUSSION_COMMENT_BY_PAGE, fetchData);
  yield takeLatest(actions.ADD_ROOM_DISCUSSION_COMMENT, postData);
  yield takeLatest(actions.ROOM_DISCUSSION_LIKE_COMMENT, postData);
  yield takeLatest(actions.ROOM_DISCUSSION_REPORT, postData);
  yield takeLatest(actions.EDIT_ROOM_DISCUSSION_COMMENT, putData);
  yield takeLatest(actions.DELETE_ROOM_DISCUSSION_COMMENT, deleteData);
  yield takeLatest(actions.GET_DISCUSSION_COMMENT_REACTION_LIST, fetchData);

  // Room Discussion Replies
  yield takeLatest(actions.GET_ROOM_DISCUSSION_COMMENT_REPLIES, fetchData);
  yield takeLatest(actions.ADD_ROOM_DISCUSSION_COMMENT_REPLIES, postData);
  yield takeLatest(actions.LIKE_ROOM_DISCUSSION_COMMENT_REPLIES, postData);
  yield takeLatest(actions.EDIT_ROOM_DISCUSSION_COMMENT_REPLIES, putData);
  yield takeLatest(actions.DELETE_ROOM_DISCUSSION_COMMENT_REPLIES, deleteData);
  yield takeLatest(actions.GET_DISCUSSION_COMMENT_REPLIES_REACTION_LIST, fetchData);

  //Poll
  yield takeLatest(actions.VOTE_ROOM_DISCUSSION_POLL, postData);

  yield takeLatest(actions.RESPOND_GOING_TO_MEETING, postData);
  yield takeLatest(actions.JOIN_ROOM_REQUEST, postData);
  yield takeLatest(actions.RESPOND_TO_INVITATION_REQUEST, postData);
  yield takeLatest(actions.REJECT_TO_INVITATION_REQUEST, postData);
  yield takeLatest(actions.LEAVE_ROOM_REQUEST, postData);
  // Meetings Video
  yield takeLatest(actions.GET_MEETING_VIDEO, fetchData);
  yield takeLatest(actions.ADD_MEETING_VIDEO, postData);

  // Room Members
  yield takeLatest(actions.GET_ROOM_MEMBERS, fetchData);
  yield takeLatest(actions.UPDATE_ROOM_MEMBER, putData);
  yield takeLatest(actions.GET_ROOM_MEMBERS_BY_PAGE, fetchData);

  // Remove Room Members
  yield takeLatest(actions.REMOVE_ROOM_MEMBERS, postData);
  yield takeLatest(actions.INVITE_MEMBER_REQUEST, postData);
  yield takeLatest(actions.MIGRATE_COURSE_ENROLLED_USERS, postData);

  //Room assessment
  yield takeLatest(actions.GET_ASSESSMENT, fetchData);
  yield takeLatest(actions.GET_ASSESSMENT_BY_ID, fetchData);
  yield takeLatest(actions.ADD_ASSESSMENT, postData);
  yield takeLatest(actions.EDIT_ASSESSMENT, putData);
  yield takeLatest(actions.DELETE_ASSESSMENT, deleteData);
  yield takeLatest(actions.GET_ASSESSMENT_ANSWER, fetchData);
  yield takeLatest(actions.GET_ASSESSMENT_ANSWER_BY_ID, fetchData);
  yield takeLatest(actions.ADD_ASSESSMENT_ANSWER, postData);
  yield takeLatest(actions.EDIT_ASSESSMENT_ANSWER, putData);
  yield takeLatest(actions.DELETE_ASSESSMENT_ANSWER, deleteData);

  // Assessment Question
  yield takeLatest(actions.GET_ASSESSMENT_QUESTION_COMMENT, fetchData);
  yield takeLatest(actions.GET_ASSESSMENT_QUESTION_COMMENT_BY_PAGE, fetchData);
  yield takeLatest(actions.ADD_ASSESSMENT_QUESTION_COMMENT, postData);
  yield takeLatest(actions.EDIT_ASSESSMENT_QUESTION_COMMENT, putData);
  yield takeLatest(actions.DELETE_ASSESSMENT_QUESTION_COMMENT, deleteData);
  // AssessmentAnswer
  yield takeLatest(actions.GET_ASSESSMENT_ANSWER_COMMENT, fetchData);
  yield takeLatest(actions.GET_ASSESSMENT_ANSWER_COMMENT_BY_PAGE, fetchData);
  yield takeLatest(actions.ADD_ASSESSMENT_ANSWER_COMMENT, postData);
  yield takeLatest(actions.EDIT_ASSESSMENT_ANSWER_COMMENT, putData);
  yield takeLatest(actions.DELETE_ASSESSMENT_ANSWER_COMMENT, deleteData);
  // Class List
  yield takeLatest(actions.GET_LIVE_CLASS, fetchData);

  yield takeLatest(actions.GET_COURSE_LIVE_CLASS, fetchData);
  yield takeLatest(actions.ADD_COURSE_LIVE_CLASS, postData);
  yield takeLatest(actions.EDIT_COURSE_LIVE_CLASS, putData);
  yield takeLatest(actions.DELETE_COURSE_LIVE_CLASS, deleteData);

  // Get Mock test exam
  yield takeLatest(actions.GET_MOCK_TEST, fetchData);
  yield takeLatest(actions.GET_MOCK_TEST_COURSES, fetchData);
  yield takeLatest(actions.GET_MOCK_TEST_COURSES_BY_PAGE, fetchData);
  yield takeLatest(actions.GET_MOCK_TEST_REPORT, fetchData);
  yield takeLatest(actions.GET_MOCK_TEST_REPORT_ADMIN_PARTNER, fetchData);

  // Submit Mock Test
  yield takeLatest(actions.SUBMIT_MOCK_TEST, postData);

  // Dropdowns
  yield takeLatest(actions.GET_USER_DROPDOWN, fetchData);
  yield takeLatest(actions.GET_USER_DROPDOWN_CLIENT, fetchData);
  yield takeLatest(actions.GET_COURSE_DROPDOWN, fetchData);
  yield takeLatest(actions.GET_SUBJECT_CONTENT_DROPDOWN, fetchData);

  // Payments
  yield takeLatest(actions.PAYMENT_REQUEST, postData);
  yield takeLatest(actions.PURCHASE_HISTORY_REQUEST, fetchData);

  // finance
  yield takeLatest(actions.GET_COUPON, fetchData);
  yield takeLatest(actions.ADD_COUPON, postData);
  yield takeLatest(actions.EDIT_COUPON, putData);
  yield takeLatest(actions.DELETE_COUPON, deleteData);
  yield takeLatest(actions.GET_COUPON_BY_ID, fetchData);

  // ledger
  yield takeLatest(actions.GET_LEDGER, fetchData);
  yield takeLatest(actions.EDIT_LEDGER_ORDER, putData);
  // yield takeLatest(actions.ADD_COUPON, postData);

  // Verify
  yield takeLatest(actions.VOUCHER_VERIFY, postData);
  yield takeLatest(actions.USE_FREE_VOUCHER, postData);

  // Extra
  yield takeLatest(actions.GET_WEBSITE_INFO, fetchData);

  //NOTES
  //notes course
  yield takeLatest(actions.GET_NOTES_COURSE_REQUEST, fetchData);
  yield takeLatest(actions.GET_NOTES_COURSE_BY_SLUG_REQUEST, fetchData);
  yield takeLatest(actions.ADD_NOTES_COURSE_REQUEST, postData);
  yield takeLatest(actions.EDIT_NOTES_COURSE_REQUEST, putData);
  yield takeLatest(actions.DELETE_NOTES_COURSE_REQUEST, deleteData);

  //notes subject
  yield takeLatest(actions.GET_NOTES_SUBJECT_REQUEST, fetchData);
  yield takeLatest(actions.GET_NOTES_SUBJECT_BY_SLUG_REQUEST, fetchData);
  yield takeLatest(actions.ADD_NOTES_SUBJECT_REQUEST, postData);
  yield takeLatest(actions.EDIT_NOTES_SUBJECT_REQUEST, putData);
  yield takeLatest(actions.DELETE_NOTES_SUBJECT_REQUEST, deleteData);

  //notes chapter
  yield takeLatest(actions.GET_NOTES_CHAPTER_REQUEST, fetchData);
  yield takeLatest(actions.GET_NOTES_CHAPTER_BY_SLUG_REQUEST, fetchData);
  yield takeLatest(actions.ADD_NOTES_CHAPTER_REQUEST, postData);
  yield takeLatest(actions.EDIT_NOTES_CHAPTER_REQUEST, putData);
  yield takeLatest(actions.DELETE_NOTES_CHAPTER_REQUEST, deleteData);

  //notes content
  yield takeLatest(actions.GET_NOTES_CONTENT_REQUEST, fetchData);
  yield takeLatest(actions.SEARCH_VIDEO, fetchData);
  yield takeLatest(actions.GET_NOTES_CONTENT_BY_SLUG_REQUEST, fetchData);
  yield takeLatest(actions.ADD_NOTES_CONTENT_REQUEST, postData);
  yield takeLatest(actions.EDIT_NOTES_CONTENT_REQUEST, putData);
  yield takeLatest(actions.DELETE_NOTES_CONTENT_REQUEST, deleteData);

  //notes category
  yield takeLatest(actions.GET_NOTES_CATEGORY_REQUEST, fetchData);
  yield takeLatest(actions.ADD_NOTES_CATEGORY_REQUEST, postData);
  yield takeLatest(actions.EDIT_NOTES_CATEGORY_REQUEST, putData);
  yield takeLatest(actions.DELETE_NOTES_CATEGORY_REQUEST, deleteData);

  //notes landing
  yield takeLatest(actions.GET_LANDING_NOTES_COURSE_REQUEST, fetchData);
  yield takeLatest(actions.GET_LANDING_NOTES_COURSE_BY_PAGE, fetchData);
  yield takeLatest(actions.GET_LANDING_NOTES_COURSE_DETAIL_REQUEST, fetchData);
  yield takeLatest(actions.GET_LANDING_NOTES_SUBJECT_DETAIL_REQUEST, fetchData);

  //partner
  yield takeLatest(actions.GET_PARTNER_REQUEST, fetchData);
  yield takeLatest(actions.ADD_PARTNER_REQUEST, postData);
  yield takeLatest(actions.EDIT_PARTNER_REQUEST, putData);
  yield takeLatest(actions.GET_PARTNER_CLIENT_REQUEST, fetchData);
  yield takeLatest(actions.GET_PARTNER_INFO_REQUEST, fetchData);

  //Partner Client Payment
  yield takeLatest(actions.GET_PARTNER_CLIENT_PAYMENT_REQUEST, fetchData);

  //mock test leaderboard
  yield takeLatest(actions.GET_MOCKTEST_LEADERBOARD, fetchData);

  //segment
  yield takeLatest(actions.GET_SEGMENT_REQUEST, fetchData);
  yield takeLatest(actions.ADD_SEGMENT_REQUEST, postData);
  yield takeLatest(actions.EDIT_SEGMENT_REQUEST, putData);
  yield takeLatest(actions.DELETE_SEGMENT_REQUEST, deleteData);
  //physical classroom
  yield takeLatest(actions.GET_PHYSICAL_CLASS_REQUEST, fetchData);
  yield takeLatest(actions.GET_PHYSICAL_CLASS_INFO_REQUEST, fetchData);
  yield takeLatest(actions.ADD_PHYSICAL_CLASS_REQUEST, postData);
  yield takeLatest(actions.EDIT_PHYSICAL_CLASS_REQUEST, putData);
  yield takeLatest(actions.DELETE_PHYSICAL_CLASS_REQUEST, deleteData);

  //physical classroom students
  yield takeLatest(actions.GET_PHYSICAL_CLASS_STUDENT_REQUEST, fetchData);
  yield takeLatest(actions.ADD_PHYSICAL_CLASS_STUDENT_REQUEST, postData);
  yield takeLatest(actions.EDIT_PHYSICAL_CLASS_STUDENT_REQUEST, putData);
  yield takeLatest(actions.DELETE_PHYSICAL_CLASS_STUDENT_REQUEST, deleteData);

  //physical classroom attendance
  yield takeLatest(actions.GET_PHYSICAL_CLASS_ATTENDANCE_REQUEST, fetchData);
  yield takeLatest(actions.ADD_PHYSICAL_CLASS_ATTENDANCE_REQUEST, postData);
  yield takeLatest(actions.EDIT_PHYSICAL_CLASS_ATTENDANCE_REQUEST, putData);

  //physical classroom student list
  yield takeLatest(actions.GET_PHYSICAL_CLASS_STUDENT_LIST_REQUEST, fetchData);

  //Physical classroom batches
  yield takeLatest(actions.GET_PHYSICAL_CLASS_BATCHES_REQUEST, fetchData);
  yield takeLatest(actions.Add_PHYSICAL_CLASS_BATCHES_REQUEST, postData);
  yield takeLatest(actions.Edit_PHYSICAL_CLASS_BATCHES_REQUEST, putData);
  yield takeLatest(actions.Delete_PHYSICAL_CLASS_BATCHES_REQUEST, deleteData);

  //physical class list (landing)
  yield takeLatest(actions.GET_PHYSICAL_CLASS_LIST_REQUEST, fetchData);
  yield takeLatest(actions.GET_PHYSICAL_CLASS_LIST_DETAIL_REQUEST, fetchData);
  yield takeLatest(actions.POST_PHYSICAL_CLASS_BOOK_REQUEST, postData);

  //physical class student requests lists
  yield takeLatest(actions.GET_PHYSICAL_CLASS_BOOK_STUDENT_LIST_REQUEST, fetchData);
  yield takeLatest(actions.UPDATE_PHYSICAL_CLASS_BOOK_STUDENT_LIST_REQUEST, putData);

  //physical class student requests lists task
  yield takeLatest(actions.GET_PHYSICAL_CLASS_STUDENT_TASK_REQUEST, fetchData);
  yield takeLatest(actions.ADD_PHYSICAL_CLASS_STUDENT_TASK_REQUEST, postData);
  yield takeLatest(actions.EDIT_PHYSICAL_CLASS_STUDENT_TASK_REQUEST, putData);
  yield takeLatest(actions.DELETE_PHYSICAL_CLASS_STUDENT_TASK_REQUEST, deleteData);

  // Chat
  yield takeLatest(actions.GET_ALL_THREADS, fetchData);
  yield takeLatest(actions.GET_ALL_THREAD_CHATS, fetchData);

  //Task
  yield takeLatest(actions.GET_TASK_REQUEST, fetchData);
  yield takeLatest(actions.GET_TASK_BY_ID_REQUEST, fetchData);
  yield takeLatest(actions.ADD_TASK_REQUEST, postData);
  yield takeLatest(actions.EDIT_TASK_REQUEST, putData);
  yield takeLatest(actions.DELETE_TASK_REQUEST, deleteData);

  //task Inquiries comment
  yield takeLatest(actions.GET_TASK_COMMENTS_REQUEST, fetchData);
  yield takeLatest(actions.ADD_TASK_COMMENTS_REQUEST, postData);
  yield takeLatest(actions.EDIT_TASK_COMMENTS_REQUEST, putData);
  yield takeLatest(actions.DELETE_TASK_COMMENTS_REQUEST, deleteData);

  // All assessment
  yield takeLatest(actions.GET_ALL_ASSESSMENT_REQUEST, fetchData);

  //course free trials
  yield takeLatest(actions.GET_COURSE_FREE_TRIALS, fetchData);

  //college
  yield takeLatest(actions.BECOME_COLLEGE_AMBASSADOR_REQUEST, postData);
  yield takeLatest(actions.GET_LANDING_NEPAL_COLLEGE_DROPDOWN_REQUEST, fetchData);
  yield takeLatest(actions.GET_COLLEGE_AMBASSADOR_FORM_LIST_REQUEST, fetchData);
  yield takeLatest(actions.GET_COLLEGE_AMBASSADOR_FORM_BY_ID_REQUEST, fetchData);
  yield takeLatest(actions.EDIT_COLLEGE_AMBASSADOR_FORM_REQUEST, putData);
  yield takeLatest(actions.GET_COLLEGE_LIST_REQUEST, fetchData);
  yield takeLatest(actions.ADD_COLLEGE_REQUEST, postData);
  yield takeLatest(actions.EDIT_COLLEGE_REQUEST, putData);
  yield takeLatest(actions.DELETE_COLLEGE_REQUEST, deleteData);

  yield all([authWatcher(), contactWatcher(), coreWatcher(), informationWatcher()]);
}
