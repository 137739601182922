import {
  GET_REFER_DETAIL_FAILED,
  GET_REFER_DETAIL_SUCCEEDED,
  GET_REFER_LIST,
  GET_REFER_LIST_FAILED,
  GET_REFER_LIST_SUCCEEDED,
} from '../actions';
import { IRefer } from '../interfaces/refer';
import { getLocalStore } from 'next-persist';

const initialState: IRefer = {
  refer: {
    data: [],
    loading: false,
    total: 0,
  },
  referDetail: {
    data: {},
  },
};
const persistedState = getLocalStore('referReducer', initialState);

const referReducer = (state = persistedState, action) => {
  switch (action.type) {
    case GET_REFER_LIST:
      return {
        ...state,
        refer: {
          ...state.refer,
          loading: true,
        },
      };
    case GET_REFER_LIST_SUCCEEDED:
      return {
        ...state,
        refer: {
          data: action.data,
          loading: false,
        },
      };
    case GET_REFER_LIST_FAILED:
      return {
        ...state,
        refer: {
          ...state.refer,
          loading: false,
        },
      };
    case GET_REFER_DETAIL_SUCCEEDED:
      return {
        ...state,
        referDetail: {
          data: action.data,
        },
      };
    case GET_REFER_DETAIL_FAILED:
      return {
        ...state,
      };
    default:
      return {
        ...state,
      };
  }
};

export default referReducer;
