import {
  GET_USER_BASIC_INFO_SUCCEEDED,
  GET_USER_BASIC_INFO_FAILED,
  EDIT_USER_BASIC_INFO_SUCCEEDED,
  EDIT_USER_BASIC_INFO_FAILED,
  GET_USER_EDUCATION_INFO_SUCCEEDED,
  GET_USER_EDUCATION_INFO_FAILED,
  ADD_USER_EDUCATION_INFO_SUCCEEDED,
  ADD_USER_EDUCATION_INFO_FAILED,
  EDIT_USER_EDUCATION_INFO_SUCCEEDED,
  EDIT_USER_EDUCATION_INFO_FAILED,
  DELETE_USER_EDUCATION_INFO_SUCCEEDED,
  DELETE_USER_EDUCATION_INFO_FAILED,
  GET_USER_EXPERIENCE_INFO_SUCCEEDED,
  GET_USER_EXPERIENCE_INFO_FAILED,
  ADD_USER_EXPERIENCE_INFO_SUCCEEDED,
  ADD_USER_EXPERIENCE_INFO_FAILED,
  EDIT_USER_EXPERIENCE_INFO_SUCCEEDED,
  EDIT_USER_EXPERIENCE_INFO_FAILED,
  DELETE_USER_EXPERIENCE_INFO_SUCCEEDED,
  DELETE_USER_EXPERIENCE_INFO_FAILED,
  GET_USER_SOCIAL_INFO_SUCCEEDED,
  GET_USER_SOCIAL_INFO_FAILED,
  EDIT_USER_SOCIAL_INFO_SUCCEEDED,
  EDIT_USER_SOCIAL_INFO_FAILED,
  SET_PROFILE_DATA,
  SET_PROFILE_EDIT_DATA,
  SET_PROFILE_ERROR_DATA,
  GET_COURSE_LIST,
  GET_COURSE_WISHLIST,
  GET_COURSE_WISHLIST_SUCCEEDED,
  GET_COURSE_WISHLIST_FAILED,
} from '../../src/actions';
import { IProfile } from '../interfaces/profile';
import { getLocalStore } from 'next-persist';

const initialState: IProfile = {
  basic_info: {},
  education: {
    data: [],
  },
  experience: {
    data: [],
  },
  social_info: {},
  helperObj: {
    editBasicInfo: false,
    editSocialInfo: false,
    addEducation: false,
    editEducation: false,
    addExperience: false,
    editExperience: false,
  },
  error: {
    editBasicInfo: {},
    editSocialInfo: {},
    addEducation: {},
    editEducation: {},
    addExperience: {},
    editExperience: {},
  },
  editBasicInfoStatus: '',
  editSocialInfoStatus: '',
  addEducationStatus: '',
  editEducationStatus: '',
  addExperienceStatus: '',
  editExperienceStatus: '',
  getCourseWishList: '',
  wishlist: {
    data: []
  }
};
const persistedState = getLocalStore('profile', initialState);

const profile = (state = persistedState, action) => {
  switch (action.type) {
    case GET_USER_BASIC_INFO_SUCCEEDED:
      return {
        ...state,
        basic_info: action.data.data,
      };

    case GET_USER_BASIC_INFO_FAILED:
      return {
        ...state,
      };

    case EDIT_USER_BASIC_INFO_SUCCEEDED:
      return {
        ...state,
        basic_info: action.data.data,
        helperObj: {
          ...state.helperObj,
          editBasicInfo: false,
        },
        error: { ...state.error, editBasicInfo: {} },
        editBasicInfoStatus: 'success',
      };

    case EDIT_USER_BASIC_INFO_FAILED:
      return {
        ...state,
        error: { ...state.error, editBasicInfo: action.error },
        editBasicInfoStatus: 'failure',
      };
    case GET_USER_SOCIAL_INFO_SUCCEEDED:
      return {
        ...state,
        social_info: action.data.data,
      };

    case GET_USER_SOCIAL_INFO_FAILED:
      return {
        ...state,
      };

    case EDIT_USER_SOCIAL_INFO_SUCCEEDED:
      return {
        ...state,
        social_info: action.data.data,
        helperObj: {
          ...state.helperObj,
          editSocialInfo: false,
        },
        error: { ...state.error, editBasicInfo: {} },
        editSocialInfoStatus: 'success',
      };

    case EDIT_USER_SOCIAL_INFO_FAILED:
      return {
        ...state,
        error: { ...state.error, editSocialInfo: action.error },
        editSocialInfoStatus: 'failure',
      };

    case GET_USER_EDUCATION_INFO_SUCCEEDED:
      return {
        ...state,
        education: action.data,
      };

    case GET_USER_EDUCATION_INFO_FAILED:
      return {
        ...state,
      };
    case ADD_USER_EDUCATION_INFO_SUCCEEDED:
      return {
        ...state,
        education: {
          ...state.education,
          data: [action.data.success, ...state.education.data],
        },
        helperObj: {
          ...state.helperObj,
          addEducation: false,
        },
        error: { ...state.error, addEducation: {} },
        addEducationStatus: 'success',
      };

    case ADD_USER_EDUCATION_INFO_FAILED:
      return {
        ...state,
        error: { ...state.error, addEducation: action.error },
        addEducationStatus: 'failure',
      };
    case EDIT_USER_EDUCATION_INFO_SUCCEEDED:
      return {
        ...state,
        education: {
          ...state.education,
          data: state.education.data.map((each: any) =>
            each.id == action.data.data.id ? action.data.data : each
          ),
        },
        helperObj: {
          ...state.helperObj,
          editEducation: false,
        },
        error: { ...state.error, editEducation: {} },
        editEducationStatus: 'success',
      };

    case EDIT_USER_EDUCATION_INFO_FAILED:
      return {
        ...state,
        error: { ...state.error, editEducation: action.error },
        editEducationStatus: 'failure',
      };
    case DELETE_USER_EDUCATION_INFO_SUCCEEDED:
      return {
        ...state,
        education: {
          ...state.education,
          data: state.education.data.filter(
            (each: any) => each.id !== action.data.data.id
          ),
        },
      };

    case DELETE_USER_EDUCATION_INFO_FAILED:
      return {
        ...state,
      };

    case GET_USER_EXPERIENCE_INFO_SUCCEEDED:
      return {
        ...state,
        experience: action.data,
      };

    case GET_USER_EXPERIENCE_INFO_FAILED:
      return {
        ...state,
      };
    case ADD_USER_EXPERIENCE_INFO_SUCCEEDED:
      return {
        ...state,
        experience: {
          ...state.experience,
          data: [action.data.success, ...state.experience.data],
        },
        helperObj: {
          ...state.helperObj,
          addExperience: false,
        },
        error: { ...state.error, addExperience: {} },
        addExperienceStatus: 'success',
      };

    case ADD_USER_EXPERIENCE_INFO_FAILED:
      return {
        ...state,
        error: { ...state.error, addExperience: action.error },
        addExperienceStatus: 'failure',
      };
    case EDIT_USER_EXPERIENCE_INFO_SUCCEEDED:
      return {
        ...state,
        experience: {
          ...state.experience,
          data: state.experience.data.map((each: any) =>
            each.id == action.data.data.id ? action.data.data : each
          ),
        },
        helperObj: {
          ...state.helperObj,
          editExperience: false,
        },
        error: { ...state.error, editExperience: {} },
        editExperienceStatus: 'success',
      };

    case EDIT_USER_EXPERIENCE_INFO_FAILED:
      return {
        ...state,
        error: { ...state.error, editExperience: action.error },
        editExperienceStatus: 'failure',
      };
    case DELETE_USER_EXPERIENCE_INFO_SUCCEEDED:
      return {
        ...state,
        experience: {
          ...state.experience,
          data: state.experience.data.filter(
            (each: any) => each.id !== action.data.data.id
          ),
        },
      };

    case DELETE_USER_EXPERIENCE_INFO_FAILED:
      return {
        ...state,
      };
    case GET_COURSE_WISHLIST:
      return {
        ...state,
        getCourseWishList: "request"
      }
    case GET_COURSE_WISHLIST_SUCCEEDED:
      return {
        ...state,
        getCourseWishList: "success",
        wishlist: action.data,
      };

    case GET_COURSE_WISHLIST_FAILED:
      return {
        ...state,
        getCourseWishList: "failure"
      };
    case SET_PROFILE_DATA:
      return {
        ...state,
        [action.payload.data.name]: {
          ...state[action.payload.data.name],
          [action.payload.data.key]: action.payload.data.value,
        },
      };
    case SET_PROFILE_EDIT_DATA:
      return {
        ...state,
        [action.payload.data.key]: action.payload.data.value,
      };
    case SET_PROFILE_ERROR_DATA:
      return {
        ...state,
        error: {
          ...state.error,
          [action.payload.data.name]: {
            ...state.error[action.payload.data.name],
            [action.payload.data.key]: action.payload.data.value,
          },
        },
      };
    default:
      return state;
  }
};

export default profile;
