import {
  CLEAR_NOTIFICATION_STATE,
  GET_NOTIFICATIONS,
  GET_NOTIFICATIONS_BY_PAGE,
  GET_NOTIFICATIONS_BY_PAGE_FAILED,
  GET_NOTIFICATIONS_BY_PAGE_SUCCEEDED,
  GET_NOTIFICATIONS_FAILED,
  GET_NOTIFICATIONS_SUCCEEDED,
  READ_ALL_NOTIFICATIONS,
  READ_ALL_NOTIFICATIONS_FAILED,
  READ_ALL_NOTIFICATIONS_SUCCEEDED,
  READ_SINGLE_NOTIFICATION,
  READ_SINGLE_NOTIFICATION_FAILED,
  READ_SINGLE_NOTIFICATION_SUCCEEDED,
  REMOVE_LIVE_NOTIFICATION_MESSAGE,
  SEND_NOTIFICATION_FOR_TUTOR_REVIEW,
  SEND_NOTIFICATION_FOR_TUTOR_REVIEW_FAILED,
  SEND_NOTIFICATION_FOR_TUTOR_REVIEW_SUCCEEDED,
  SET_LIVE_NOTIFICATION_MESSAGE,
} from '../actions';
import { INotification } from '../interfaces/notifications';
import { getLocalStore } from 'next-persist';
import moment from 'moment';
import { Avatar } from 'antd';

const initialState: INotification = {
  notification: {
    data: [],
    loading: false,
    moreLoading: false,
    status: false,
    read: false,
    unreadCount: 0,
    meta: {},
  },
  sendNotificationForReview: {
    success: false,
    loading: false,
  },
  arrNotifications: {
    data: [],
  },
  liveNotification: {},
};
const persistedState = getLocalStore('notificationReducer', initialState);

const notificationReducer = (state = persistedState, action) => {
  switch (action.type) {
    case SET_LIVE_NOTIFICATION_MESSAGE:
      return {
        ...state,
        liveNotification: action.payload,
        notification: {
          ...state.notification,
          data: {
            data: [
              notificationObjModify(action.payload),
              ...state?.notification?.data?.data,
            ],
          },
          unreadCount: action.payload.notification_count,
        },
      };
    case REMOVE_LIVE_NOTIFICATION_MESSAGE:
      const notificationArr = state.notification.data.data.filter(
        (each: any) => each?.id !== action?.payload?.id
      );
      return {
        ...state,
        notification: {
          ...state.notification,
          data: { data: notificationArr },
          unreadCount: action.payload.notification_count,
        },
      };

    case GET_NOTIFICATIONS:
      return {
        ...state,
        notification: {
          ...state.notification,
          loading: true,
          moreLoading: false,
        },
      };
    case GET_NOTIFICATIONS_SUCCEEDED:
      const notificationDatas = [];
      action.data.data?.map((each) => {
        var dbNotification = notificationObjModify(each);
        notificationDatas?.push(dbNotification);
      });

      return {
        ...state,
        notification: {
          data: {
            data: notificationDatas,
          },
          status: true,
          loading: false,
          unreadCount: action.data.unreadnotification,
          meta: action.data.meta,
        },
      };
    case GET_NOTIFICATIONS_FAILED:
      return {
        ...state,
        notification: {
          ...state.notification,
          status: false,
          loading: false,
        },
      };
    case GET_NOTIFICATIONS_BY_PAGE:
      return {
        ...state,
        notification: {
          ...state.notification,
          moreLoading: true,
        },
      };
    case GET_NOTIFICATIONS_BY_PAGE_SUCCEEDED:
      return {
        ...state,
        notification: {
          ...state.notification,
          data: action.data.data,
          link: action.data.link,
          meta: action.data.meta,
          moreLoading: false,
        },
      };
    case GET_NOTIFICATIONS_BY_PAGE_FAILED:
      return {
        ...state,
      };
    case READ_ALL_NOTIFICATIONS:
      return {
        ...state,
        notification: {
          ...state.notification,
          // loading: true,
        },
      };
    case READ_ALL_NOTIFICATIONS_SUCCEEDED:
      const newData = {
        data: state?.notification?.data?.data?.map((each) => ({
          ...each,
          readAt: action.data.read_at,
        })),
      };
      return {
        ...state,
        notification: {
          ...state.notification,
          data: newData,
          status: true,
          // loading: false,
          unreadCount: 0,
        },
      };
    case READ_ALL_NOTIFICATIONS_FAILED:
      return {
        ...state,
      };
    case READ_SINGLE_NOTIFICATION_SUCCEEDED:
      const findIndex = state?.notification?.data?.data?.findIndex(
        (each) => each.id === action.data.data.id
      );
      let myArr: any = state?.notification?.data?.data;
      myArr[findIndex].readAt = action.data.data.read_at;
      return {
        ...state,
        notification: {
          ...state.notification,
          read: true,
          loading: false,
          data: state?.notification?.data,
          unreadCount:
            action.data.data.read_at === null
              ? state.notification.unreadCount !== null
                ? state.notification.unreadCount + 1
                : 1
              : state.notification.unreadCount - 1,
        },
      };
    case READ_SINGLE_NOTIFICATION_FAILED:
      return {
        ...state,
      };
    case SEND_NOTIFICATION_FOR_TUTOR_REVIEW:
      return {
        ...state,
        sendNotificationForReview: {
          loading: true,
          success: false,
        },
      };
    case SEND_NOTIFICATION_FOR_TUTOR_REVIEW_SUCCEEDED:
      return {
        ...state,
        sendNotificationForReview: {
          loading: false,
          success: true,
        },
      };
    case SEND_NOTIFICATION_FOR_TUTOR_REVIEW_FAILED:
      return {
        ...state,
        sendNotificationForReview: {
          loading: false,
          success: false,
        },
      };
    case CLEAR_NOTIFICATION_STATE:
      return {
        ...state,
        notification: {
          data: [],
          loading: false,
          moreLoading: false,
          status: false,
          read: false,
        },
      };
    default:
      return {
        ...state,
      };
  }
};

export default notificationReducer;

export const notificationObjModify = (each) => {
  var dbNotification = {
    id: each?.id,
    title: each?.message,
    time: moment(each?.created_at).fromNow(),
    slug: each?.slug,
    avatar: each?.object?.image ? (
      <Avatar size={45} src={each?.object?.image} />
    ) : each?.event_by?.avatar ? (
      <Avatar size={45} src={each?.event_by?.avatar} />
    ) : each?.object?.first_name ? (
      <Avatar size="large" style={{ backgroundColor: '#2352aa' }}>
        {each?.object?.first_name?.charAt(0).toUpperCase()}
        {each?.object?.last_name?.charAt(0).toUpperCase()}
      </Avatar>
    ) : (
      <Avatar size="large" style={{ backgroundColor: '#2352aa' }}>
        {each?.event_by?.first_name?.charAt(0).toUpperCase()}
        {each?.event_by?.last_name?.charAt(0).toUpperCase()}
      </Avatar>
    ),
    readAt: each?.read_at,
    object_id: each?.object_id,
    event_type: each?.event_type,
    content_type: each?.content_type,
    object_slug: each?.object?.slug || each?.object?.room?.slug,
    oid: each?.object?.id,
    postId: each?.object?.post?.id,
    object_type: each?.object?.type,
    assessment_id: each?.object?.assessment_id,
  };
  return dbNotification;
};
